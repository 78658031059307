
export default class LeadName extends HTMLElement {

    constructor() {
        super();
        this.attachShadow({ mode:'open' });
    }

    connectedCallback() {
        this.shadowRoot.innerHTML = this.render();
    }
    private get isAnonymousMode() { return document.cookie.includes('fameanon-mode=true'); }

    render = () => {
        const firstName = this.getAttribute('data-first-name');
        const lastName = this.getAttribute('data-last-name');
        const firstNameToDisplay = firstName ? this.isAnonymousMode ? 'Anonymous' : firstName : '';
        const lastNameToDisplay = lastName ? this.isAnonymousMode ? 'User' : lastName : '';

        return `${firstNameToDisplay} ${lastNameToDisplay}`;
    };
}

customElements.define('lead-name', LeadName);
